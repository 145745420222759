/* EduDiatype */

@font-face {
  font-family: 'EduDiatype';
  font-style: normal;
  font-weight: 100;
  src: url('./fonts/EduDiatype-Thin.otf') format('opentype');
}

@font-face {
  font-family: 'EduDiatype';
  font-style: italic;
  font-weight: 100;
  src: url('./fonts/EduDiatype-ThinItalic.otf') format('opentype');
}

@font-face {
  font-family: 'EduDiatype';
  font-style: normal;
  font-weight: 400;
  src: url('./fonts/EduDiatype-Light.otf') format('opentype');
}

@font-face {
  font-family: 'EduDiatype';
  font-style: italic;
  font-weight: 400;
  src: url('./fonts/EduDiatype-LightItalic.otf') format('opentype');
}

@font-face {
  font-family: 'EduDiatype';
  font-style: normal;
  font-weight: 500;
  src: url('./fonts/EduDiatype-Regular.otf') format('opentype');
}

@font-face {
  font-family: 'EduDiatype';
  font-style: italic;
  font-weight: 500;
  src: url('./fonts/EduDiatype-RegularItalic.otf') format('opentype');
}

@font-face {
  font-family: 'EduDiatype';
  font-style: normal;
  font-weight: 600;
  src: url('./fonts/EduDiatype-Medium.otf') format('opentype');
}

@font-face {
  font-family: 'EduDiatype';
  font-style: italic;
  font-weight: 600;
  src: url('./fonts/EduDiatype-MediumItalic.otf') format('opentype');
}

@font-face {
  font-family: 'EduDiatype';
  font-style: normal;
  font-weight: 700;
  src: url('./fonts/EduDiatype-Bold.otf') format('opentype');
}

@font-face {
  font-family: 'EduDiatype';
  font-style: italic;
  font-weight: 700;
  src: url('./fonts/EduDiatype-BoldItalic.otf') format('opentype');
}
